import DiamondFile from "@/types/DiamondFile"
import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class CareerBanner {
  @JsonProperty("career_banner_id") id?: number

  @JsonProperty() title?: string

  @JsonProperty("title_sub") subTitle?: string

  @JsonProperty() description?: string

  @JsonProperty() link?: string

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile = new DiamondFile()
}
