var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-start text-lg-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("career.moreInfo"))+" ")]),(_vm.mobileScreen)?_c('div',{staticClass:"mt-4 mt-lg-0"},[_vm._v(" "+_vm._s(_vm.$t("career.tel"))+" "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.footer.tel))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t("career.email"))),_c('a',{staticClass:"primary--text text-decoration-none",attrs:{"href":("mailto:" + (_vm.footer.email))}},[_vm._v(_vm._s(_vm.footer.email))]),_c('br'),_c('div',{staticClass:"d-inline-flex mt-4 mx-auto"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{staticClass:"mr-2",attrs:{"href":_vm.facebookUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-img',{attrs:{"src":hover ? _vm.iconFacebookHover : _vm.iconFacebook,"target":"_blank","rel":"noopener noreferrer","alt":"","width":"40px"}})],1)]}}],null,false,1653988786)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{staticClass:"mx-2",attrs:{"href":_vm.lineUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-img',{attrs:{"src":hover ? _vm.iconLineHover : _vm.iconLine,"alt":"","width":"40px"}})],1)]}}],null,false,3721691489)})],1)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("career.tel"))),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.footer.tel))]),_c('span',{staticClass:"mx-4"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.$t("career.email"))),_c('a',{staticClass:"primary--text text-decoration-none",attrs:{"href":("mailto:" + (_vm.footer.email))}},[_vm._v(_vm._s(_vm.footer.email))]),_c('br'),_c('div',{staticClass:"d-inline-flex mt-4 mx-auto"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{staticClass:"mr-2",attrs:{"href":_vm.facebookUrl,"rel":"noopener noreferrer"}},[_c('v-img',{attrs:{"src":hover ? _vm.iconFacebookHover : _vm.iconFacebook,"alt":"","width":"40px"}})],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{staticClass:"mx-2",attrs:{"href":_vm.lineUrl,"rel":"noopener noreferrer"}},[_c('v-img',{attrs:{"src":hover ? _vm.iconLineHover : _vm.iconLine,"alt":"","width":"40px"}})],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }