



































































import { Component, Watch } from "vue-property-decorator"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import CareerExecutive from "@/types/Career/CareerExecutive"
import _ from "lodash"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "CareerExecutiveComponent"
})
export default class CareerExecutiveComponent extends mixins(BreakpointMixin) {
  private data: CareerExecutive[] = [new CareerExecutive(), new CareerExecutive()]

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CareerApi.listCareerExecutives().then(({ data }) => {
      this.data = _.map(data, (d) => deserialize<CareerExecutive>(d, CareerExecutive))
    })
  }

  mounted() {
    this.fetchData()
  }
}
