





















































import { Component, Watch } from "vue-property-decorator"
import CareerFooter from "@/types/Career/CareerFooter"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "CareerFooterComponent"
})
export default class CareerFooterComponent extends mixins(BreakpointMixin) {
  private footer = new CareerFooter()
  private facebookUrl = "https://www.facebook.com/DBPcareers"
  private lineUrl = "https://lin.ee/bS3lK0z"

  /** Facebook */
  private iconFacebook = require("@/assets/icons/facebook.png")
  private iconFacebookHover = require("@/assets/icons/facebook_variant2.png")

  /** Line */
  private iconLine = require("@/assets/icons/line.png")
  private iconLineHover = require("@/assets/icons/line_variant2.png")

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CareerApi.getCareerFooter().then(({ data }) => {
      this.footer = deserialize<CareerFooter>(data, CareerFooter)
    })
  }

  mounted() {
    this.fetchData()
  }
}
