









































import { Component, Watch } from "vue-property-decorator"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import CareerBanner from "@/types/Career/CareerBanner"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "CareerBannerComponent"
})
export default class CareerBannerComponent extends mixins(BreakpointMixin) {
  private banner: CareerBanner = new CareerBanner()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CareerApi.getCareerBanner().then(({ data }) => {
      this.banner = deserialize<CareerBanner>(data, CareerBanner)
    })
  }

  mounted() {
    this.fetchData()
  }

  get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.smAndDown) {
      return "417px"
    }
    return "626px"
  }

  // eslint-disable-next-line class-methods-use-this
  toPositionSection() {
    this.$emit("position-section")
  }
}
